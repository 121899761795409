import React from 'react';

import Modal from './Modal';

import LoadingComponent from "./LoadingComponent";

import Select from 'react-select';
class ModalConfirm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            selectedOption: null,
        }
    }

    onConfirm = () => {
        this.setState({
            loading: true,
        });
        return this.props.onConfirm(this.state?.selectedOption?.value)
            .then(() => {
                this.props.onClose();
            })
            .catch((err) => {
                console.error(err, 'Error while confirming');
            })
            .finally(() => {
                this.setState({
                    loading: false,
                });
            });
    };
    handleSelectChange = (selectedOption) => {
        this.setState({ selectedOption });
    };
    render() {   
        const { message, opsCallReasons, isOpsCallForPharmacy } = this.props;
        const { loading, selectedOption } = this.state;
        const isModalForOpsPhCalling = isOpsCallForPharmacy && opsCallReasons?.length > 0 ? true : false ;
        return (
            <Modal {...this.props} header={message}>
                <div className="container-fluid">
                    {isModalForOpsPhCalling && (
                        <div className="mb-3">
                            <label htmlFor="call-reason-select">Select Call Reason:</label>
                            <Select
                                id="call-reason-select"
                                options={opsCallReasons.map((reason) => ({
                                value: reason.id,
                                label: reason.reason,
                                }))}
                                onChange={this.handleSelectChange}
                                placeholder="Choose reason for calling"
                                menuPortalTarget={document.body} 
                                menuShouldScrollIntoView={false} 
                                styles={{
                                    menuPortal: (base) => ({
                                        ...base,
                                        zIndex: 1050, 
                                    }),
                                    menu: (base) => ({
                                        ...base,
                                        maxHeight: "200px", 
                                        overflowY: "auto", 
                                    }),
                                }}
                            />
                        </div>
                    )}
                    <div className="row">
                        <div className="col">
                            {(loading) ? (
                                <LoadingComponent />
                            ) : (
                                <button className="btn btn-block btn-success" disabled = {isModalForOpsPhCalling && !this.state.selectedOption ? true : false } onClick={() => this.onConfirm()}>Confirm</button>
                            )}
                        </div>
                        <div className="col">
                            <button className="btn btn-block btn-danger" onClick={this.props.onClose}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ModalConfirm;
