import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Modal,
    ModalHeader,
    ModalBody,
    Tooltip,
  } from 'reactstrap';
import { getFollowUpRequestsForOrderId, markFollowUpsCompleted } from './UploadRxComponents/Services.js';
import { toast } from 'react-toastify';
function FetchFollowUpRequestsModal({ onClose , orderId }) {
  const [followUpRequest, setFollowUpRequest] = useState([]);
  const [completedFollowUps , setCompletedFollowUps] = useState({})
  const handleCheckboxChange = (followUpId) => {
    setCompletedFollowUps((prevState) => ({
        ...prevState,
        [followUpId]: !prevState[followUpId], 
      }));
  };


  useEffect(() => {
    const fetchFollowUpRequests = async () => {
      try {
        const followUpRequest = await getFollowUpRequestsForOrderId(orderId);
        if(followUpRequest) {
            setFollowUpRequest(followUpRequest);
        }
      } catch (error) {
        console.error("Error fetching follow Up Request:", error);
        toast.error('Failed to fetch Previous follow up requests');
      }
    };

    fetchFollowUpRequests();
  }, []);

  const markFollowUpCompleted = async() => {
    const followUpIds = Object.entries(completedFollowUps).filter(([key, value]) => value === true).map(([key, value]) => key);
    const result = await markFollowUpsCompleted(followUpIds);
    if(result){
        toast.success("Follow up completed successfully");
        if(followUpIds.length == followUpRequest.length){
            onClose()
        }else{
            setFollowUpRequest(prevState => prevState.filter(item => !followUpIds.includes(item.followUpId.toString())));
        }
    }else{
        toast.error('Failed to complete follow up')
    }
  }

  return (
    <Modal
      isOpen={true}
      style={{
        maxWidth: '600px',
        width: '100%',
        borderRadius: '4px',
        backgroundColor: '#f8f9fa',
      }}
    >
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={onClose}>
        <h4
          className="text-center"
          style={{ color: '#fff', fontWeight: 'bold' }}
        >
          Follow Up Requests ({orderId})
        </h4>
      </ModalHeader>
      <ModalBody style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px'}}>
        {
            followUpRequest.length ? 
            <>
                <div style={{  borderRadius: '8px' , marginBottom : '8px'}}>
                    <table style={{ width: '100%',  borderCollapse: 'collapse', maxHeight : '800px'}}>
                        <thead>
                            <tr style={{
                                backgroundColor: '#ECB800',
                                color: '#fff',
                                textAlign: 'left',
                                padding: '10px',
                                fontWeight: 'bold',
                            }}>
                                <th style={{ padding: '12px', borderBottom: '2px solid #ddd' }}>Completed</th>
                                <th style={{ padding: '12px', borderBottom: '2px solid #ddd' }}>Follow Up Reason</th>
                                <th style={{ padding: '12px', borderBottom: '2px solid #ddd' }}>Required From</th>
                                <th style={{ padding: '12px', borderBottom: '2px solid #ddd' }}>Date & Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {followUpRequest.map((item) => (
                                <tr key={item.followUpId} style={{
                                    backgroundColor: '#fff',
                                    borderBottom: '1px solid #ddd',
                                    transition: 'background-color 0.3s ease',
                                }} >
                                    <td style={{ padding: '12px', textAlign: 'center' }}>
                                        <input
                                            type="checkbox"
                                            id={`completedCheckbox-${item.followUpId}`}
                                            style={{
                                                cursor: 'pointer',
                                            }}
                                            checked={completedFollowUps[item.followUpId] || false}
                                            onChange={() => handleCheckboxChange(item.followUpId)}
                                        />
                                        <Tooltip placement="right" target={`completedCheckbox-${item.followUpId}`}>
                                            {'Mark as Completed'}
                                        </Tooltip>
                                    </td>
                                    <td style={{ padding: '12px' }}>{item.reason}</td>
                                    <td style={{ padding: '12px' }}>{item.requiredFrom}</td>
                                    <td style={{ padding: '12px' }}>
                                        {moment(item.dateTime).format('YYYY-MM-DD hh:mm A')}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="text-center">
                    <button disabled = {!Object.values(completedFollowUps).includes(true)} type="submit" onClick={markFollowUpCompleted} className="btn btn-primary">Mark Follow Up Completed</button>
                </div>
            </>

            : <></>
        }
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(FetchFollowUpRequestsModal);
