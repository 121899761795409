import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import Communications from '../../../images/Communications.svg';
import MarkFollowUp from '../../../images/MarkFollowUp.svg';
import SendCommsModal from "./SendCommsModal";
import FollowUpModal from "./MarkFollowUpModal";

const PharmacyActions = ({orderId}) => {
    const [showSendCommsPopup, setShowSendCommsPopup] = React.useState(false);
    const [showFollowUpPopup, setShowFollowUpPopup] = React.useState(false);

    const handlePopUps = (action) => {
        console.log('vnd',action)
        if( action == 'send-comms'){
            setShowFollowUpPopup(false);
            setShowSendCommsPopup(true);
        }else if (action == 'mark-follow-up'){
            setShowSendCommsPopup(false);
            setShowFollowUpPopup(true);
        }else if(action == 'close-popup'){
            setShowSendCommsPopup(false);
            setShowFollowUpPopup(false);
        }
    }

    return (
        <>
            <div style={{ display : 'flex', justifyContent : 'space-evenly', alignItems : 'center' }}>
            <div>
                <UncontrolledTooltip  target= {`pharmacy-action-send-comms-${orderId}`}>
                    Send Communication
                </UncontrolledTooltip>
                <img
                    id={`pharmacy-action-send-comms-${orderId}`}
                    onClick={() => handlePopUps('send-comms')}
                    src={Communications}
                    alt="Send Communication"
                    className="button-img"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                />  
            </div>
            <div>
                <UncontrolledTooltip  target= {`pharmacy-action-mark-follow-up-${orderId}`}>
                    Mark Follow Up
                </UncontrolledTooltip>
                <img
                    id={`pharmacy-action-mark-follow-up-${orderId}`}
                    onClick={() => handlePopUps('mark-follow-up')}
                    src={MarkFollowUp}
                    alt="Mark Follow Up"
                    className="button-img"
                    style={{ width: '24px', height: '24px', cursor: 'pointer' }}
                />  
            </div>
    
            </div>
            {showSendCommsPopup && <div> <SendCommsModal orderId = {orderId} onClose={handlePopUps} /></div>}
            {showFollowUpPopup && <div> <FollowUpModal isOpen = {true} orderId = {orderId} onClose={handlePopUps} /></div>}
        </>

    )

}
export default PharmacyActions;