import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavBar from '../layout/NavBar';
import {
  getOrderDetails,
  saveRemark,
  cancelOrder,
} from '../../services/api/get-pharmacy-orders';
import PharmacyCart from './PharmacyCart';
import { toast } from 'react-toastify';
import Modal from '../common/Modal';
import PharmacyClickToCallButton from './PharmacyClickToCallButton';
import ModalConfirm from '../common/ModalConfirm';
import PharmacyOrderCopayForm from './PharmacyOrderCopayForm';
import UserWallet from './UserWallet';
import CancelOrderModal from "./CancelOrderModal.jsx";
import ChangePartner from "./ChangePartner";
import PharmacyRefundAmount from "./PharmacyRefundAmount";

import CommentList from './comments/commentList';
import CommentForm from './comments/commentForm';
import appConfig from '../../config/app-config';
import {
  viewCommentRequest,
} from '../../services/api/get-pharmacy-orders';
import RetryOrder from './RetryOrder.jsx';

class PharmacyOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderDetails: [],
      userDetails: {},
      orderAmount: {},
      deliveryDetail:{},
      cartId: null,
      loading: false,
      comments: [],
      userId: null,
      copayBlockerModal: {
        isOpen: false,
      },
      confirmModal: {
        isOpen: false,
        message: 'Are you sure. You want to cancel this order?',
        onConfirm: () => {},
        onClose: () => {},
      },
      openRetryModal : false,
      orderId: null,
      remarks: '',
      subOrderDetails: []
    };
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const orderId = params.get('orderId');
    this.fetchCommentByOrderId(orderId);
    this.setState(
      {
        orderId,
      },
      this.getUserDetails
    );
  }
  getUserDetails = () => {
    const { userId, orderId } = this.state;
    if (!orderId) {
      return;
    }
    return getOrderDetails(orderId)
      .then((response) => {
        const details = response.body;
        console.log({ response }, '[getOrderDetails]');
        this.setState({
          orderDetails: details.orderDetails,
          userDetails: details.userDetails || {},
          orderAmount: details.orderAmount || {},
          deliveryDetail: details.deliveryPersonDetail ||{},
          subOrderDetails: details.subOrderDetails || []
          // remarks: cartDetails.remarks || '',
        });
      })
      .catch((err) => {
        console.error('Error in loading user details', err);
        toast.error(`Unable to load User details`);
      });
  };
  openAddCopayModal = () => {
    this.setState(
      {
        copayBlockerModal: {
          isOpen: true,
        },
      },
      this.getUserDetails,
      this.fetchPharmacyCart
    );
  };
  openConfirmModal = () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        message: 'Are you sure. You want to cancel this order?',
        onConfirm: () => {
          const { orderId } = this.state;
          return cancelOrder(orderId)
            .then((response) => {
              if (response.body.message === 'success') {
                toast.success('Order Cancelled');
              } else {
                const err = response.body.err;
                if (typeof err === 'string') {
                  toast.error(response.body.err);
                } else {
                  toast.error('Unable to cancel order');
                }
              }
              this.getUserDetails();
              return Promise.resolve();
            })
            .catch((err) => {
              console.error('Error in cancel order', err);
              toast.error('Unable to cancel order');
              return Promise.resolve();
            })

        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };
  closeConfirmModal = () => {};
  closeAddCopayModal = () => {
    this.setState(
      {
        copayBlockerModal: {
          isOpen: false,
        },
      },
      this.getUserDetails,
      this.fetchPharmacyCart
    );
  };
  saveRemarks = () => {
    const { orderId, remarks } = this.state;
    return saveRemark(orderId, remarks)
      .then((response) => {
        if (response.body.message === 'success') {
          toast.success('Remark Saved');
        } else {
          toast.error(`Unable to save remarks`);
        }
      })
      .catch((err) => {
        console.error('Error in saving remarks', err);
        toast.error(`Unable to save remarks`);
      });
  };

  handleComments = (response) => {
    const comments = [];
    if (response.message === 'success' && response.data.length) {
      // build comments
      response.data.forEach((c) => {
        comments.push({
          name: c.userName,
          message: c.text,
          time: c.createdAt,
        });
      });
    }
    return comments;
  };

  fetchCommentByOrderId = (orderId) => {
    viewCommentRequest(orderId, appConfig.REQUEST_CHAT.INTERNAL)
      .then((response) => this.handleComments(response))
      .then((comments) => {
        // setting the state
        this.setState({
          comments,
          requestChat: {
            orderId,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addComment = (comment) => {
    this.setState({
      loading: false,
      comments: [...this.state.comments, comment],
    });
  }

  fetchPharmacyCart = () => {
    return (
      <PharmacyCart
        orderDetails={this.state.orderDetails}
        orderAmount={this.state.orderAmount}
      />
    );
  };

  fetchUserWallet = () => {
    return (
      <UserWallet propOrderId={this.state.orderId} isStarHealthUser={this.state.userDetails.isStarPatient} starPolicyNo={this.state.userDetails.isStarPatient && this.state?.userDetails.starHealthPolicyNumber}/>
    )
  }

  toggleRetryModal = () => {
    this.setState({openRetryModal : !this.state.openRetryModal})
  }

  render() {
    const {
      orderId,
      comments,
      loading,
    } = this.state;
    return (
      <div className="container-fluid">
        <div className="row">
          <NavBar />
        </div>

        <div className="row mt-5 pt-1">
          <div className="col">
            <div className="d-inline-block">
              <h2 className="pl-1">
                Order #{this.state.orderId}
                <span className="badge badge-info">
                  {this.state.userDetails.partnerName}
                </span>
              </h2>
              <div className="d-flex justify-content-start align-items-center">
                <p>
                  Partner BookingId :
                  <strong>
                    {this.state.userDetails.partnerBookingId
                      ? this.state.userDetails.partnerBookingId
                      : 'NA'}
                  </strong>
                </p>
              </div>
            </div>
            {/* <div className='d-inline-block float-right '>
            <h3>
                  Order Live Staus :
                  <span className="badge badge-primary">
                    {this.state.userDetails.liveStatus
                      ? this.state.userDetails.liveStatus
                      : 'NA'}
                </span>
                </h3>
            </div> */}
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">User Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Sponsor Name</th>
                  <th scope="col">Policy Holder Name</th>
                  <th scope="col">Dependent Names</th>
                  <th scope="col">Dependent Relations</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.userDetails.userName}</td>
                  <td>{this.state.userDetails.userEmail}</td>
                  <td>
                    <PharmacyClickToCallButton
                      orderId={this.state.orderId}
                      phone={this.state.userDetails.userPhone}
                    />
                  </td>
                  <td>{this.state.userDetails.sponsorName}</td>
                  <td>{this.state.userDetails.policyHolderName}</td>
                  <td>{this.state.userDetails.patientName}</td>
                  <td>{this.state.userDetails.userRelations}</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Patient Name</th>
                  <th scope="col">Gender</th>
                  <th scope="col">Age</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Address</th>
                  <th scope="col">Prescription Url</th>
                  <th scope="col">Digitisation Id</th>
                  {/* <th scope="col">Pickup Slot</th> */}
                  {/* <th scope="col">Verified Prescription</th> */}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.userDetails.patientName}</td>
                  <td>{this.state.userDetails.patientGender}</td>
                  <td>{this.state.userDetails.patientAge}</td>
                  <td>
                    <PharmacyClickToCallButton
                      orderId={this.state.orderId}
                      phone={this.state.userDetails.patientPhone}
                    />
                  </td>
                  <td>
                    {this.state.userDetails.addressTitle} |{' '}
                    {this.state.userDetails.address}{' '}
                    {this.state.userDetails.locality}{' '}
                    {this.state.userDetails.pincode}
                  </td>
                  <td>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={this.state.userDetails.prescriptionUrl}
                    >
                      {this.state.userDetails.prescriptionUrl}
                    </a>
                  </td>
                  <td>{this.state.userDetails.digitisationId || ''}</td>
                  {/* <td>
                    {this.state.orderDetails.slotTime} |
                    {this.state.orderDetails.collectionDate}
                  </td> */}
                  {/* <td>
                    {this.state.cartDetails.verifiedPrescription !== null ? (
                      <a
                        target="_blank"
                        href={this.state.cartDetails.verifiedPrescription}
                      >
                        Open
                      </a>
                    ) : (
                      'NA'
                    )}
                  </td> */}
                </tr>
              </tbody>
            </table>
            <br></br>
            {/**
             * <ChangePartner orderId={this.state.orderId} />
             */
            }
            <br></br>
            {/**
             * <CancelOrderModal orderId={this.state.orderId} />
             */
            }
            <br></br>
            {this.state.userDetails.status === 'Failed' && <div>
              <button className="btn btn-primary" onClick = {()=> this.setState({ openRetryModal : true}) }>Retry</button>
            </div>}

            {/* Right now we dont have any delivery person details */}
            {/* <h4 className='font-weight-bold'>Delivery Person Detail</h4>
            <table className="table">
              <thead className="thead-dark">
                <tr>
                <th scope="col">Booking Id</th>
                  <th scope="col">Delivery Person Name</th>
                  <th scope="col"> Delivery Person Phone</th>
                  <th scope="col">Tracking Link</th>
                  <th scope="col">Estimate Date</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                <td>{this.state.deliveryDetail.pharmeasyOrderId}</td>
                  <td>{this.state.deliveryDetail.deliveryPersonName}</td>
                  <td> {this.state.deliveryDetail.deliveryPersonContact}
                  </td>
                  <td>
                   <a target="_blank" rel="noopener noreferrer" href={this.state.deliveryDetail.trackingLink}>{this.state.deliveryDetail.trackingLink}</a>
                  </td>
                  <td>{this.state.deliveryDetail.estimateDate}</td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
        <br></br>
        <div className="row">{this.fetchPharmacyCart()}</div>
        <div className="row">
          <PharmacyRefundAmount
            serviceKey={'pharmacy'}
            userId={this.state.userDetails.userId}
            orderId={this.state.orderId}
            orderDetails={this.state.orderDetails}
            orderAmount={this.state.orderAmount}
          />
        </div>
        <div className="row">{this.fetchUserWallet()}</div>

        {this.state.subOrderDetails && this.state.subOrderDetails.length ? (
          <div>
            <h4 className="font-weight-bold">Sub Order Status</h4>
            <div>
              <div className="hover-info-content">
                {/* <div style={{position: 'absolute', top: '100%', left: 0, display: 'none', backgroundColor: '#f5f5f5', border: '1px solid #ccc', padding:'5px'}}> */}
                {/* Additional information to show on hover */}
                <table className="table table-bordered thead-dark">
                  <tr>
                    <th>Sub Order Id</th>
                    <th>Sub Order Status</th>
                    <th>Sub Order Max Delivery Date</th>
                    <th>Sub Order Min Delivery Date</th>
                  </tr>
                  {this.state.subOrderDetails.map((subOrder) => (
                    <tr>
                      <td>{subOrder.partnerSubOrderId}</td>
                      <td>{subOrder.partnerOrderStatus}</td>
                      <td>{subOrder.maxDeliveryTime}</td>
                      <td>{subOrder.minDeliveryTime}</td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}

        <br></br>
        <div className="row">
          <div className="col-5">
            {/* <div className="form-group">
              <label
                htmlFor="exampleFormControlTextarea1"
                className="font-weight-bolder"
              >
                Remarks
              </label>
              <textarea
                value={this.state.remarks}
                onChange={(e) => this.setState({ remarks: e.target.value })}
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="3"
              ></textarea>
            </div> */}
          </div>
          <div className="col-5 d-flex align-items-center">
            {/* <button className="btn btn-success" onClick={this.saveRemarks}>
              Save Remarks
            </button> */}
            {/* Not To be used right now as we dont have medicine prices */}
            {/* <button
              style={{ marginLeft: '5px' }}
              className="btn btn-primary"
              onClick={this.openAddCopayModal}
            >
              Change Pricing
            </button> */}
          </div>
        </div>
        <div className="row">
          <div className="col mt-3">
            <CommentList loading={loading} comments={comments} />
            <div className="">
              <CommentForm
                addComment={this.addComment}
                orderId={orderId}
                commentType={appConfig.PHARMACY.pharmacyOrder}
              />
            </div>
          </div>
          <div className="col-5">
            {this.state.transactions && this.state.transactions.length ? (
              <div className="card">
                <div className="card-header">
                  <div className="row no-gutters">
                    <div className="col">Transaction History</div>
                  </div>
                </div>
                <div className="card-body">
                  <div className="container-fluid">
                    {this.state.transactions.map((t) => (
                      <div className="row" key={`${t.id}-${t.type}`}>
                        <div className="col">
                          <li>
                            {t.type === 'refund' ? 'Refund' : 'Copay'} of Rs.
                            {t.amount} on {t.formattedDate} - {t.status}
                          </li>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <br />

        <Modal
          header={`Change Pricing`}
          isOpen={this.state.copayBlockerModal.isOpen}
          onClose={this.closeAddCopayModal}
        >
          <PharmacyOrderCopayForm
            orderId={this.state.orderId}
            cartItemsResult={this.state.orderDetails}
            onSubmit={this.closeAddCopayModal}
          />
        </Modal>
        <ModalConfirm
          message={this.state.confirmModal.message}
          onConfirm={this.state.confirmModal.onConfirm}
          onClose={this.state.confirmModal.onClose}
          isOpen={this.state.confirmModal.isOpen}
        />
        {this.state.openRetryModal && <RetryOrder orderId={orderId} onClose= {this.toggleRetryModal}/>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return;
}

export default connect(mapStateToProps)(PharmacyOrderDetails);
