import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { FaTimes } from 'react-icons/fa';
import pdfIcon from '../../../images/pdf-icon.svg';
import photoIcon from '../../../images/photo-icon.svg';
import fileIcon from '../../../images/file-icon.svg';
import shieldIcon from '../../../images/shield.svg';
import eyeIcon from '../../../images/eye.svg';
import Select from 'react-select';
import StyledTappable from './StyledTappable';
import UploadingComponent from './UploadingComponent';
import LoadingComponent from './LoadingComponent';
import { connect } from 'react-redux';
import UploadModal from './UploadModal';


import {
  uploadPrescriptionForMed,
  removeFileFromPrescriptionMeds,
  submitDigitsationRequestMeds,
  getUserInfoByPhone,
  uploadPrescriptionForMedDashboard,
} from './Services.js';



const CloseIcon = styled(FaTimes)`
  font-size: 14px;
  color: white;
`;

const CloseContainer = styled(StyledTappable)`
  position: absolute;
  top: -5px;
  right: -5px;
  background: #ff7268;
  border-radius: 6px;
  padding: 2px;
`;


const AttachContainer = styled.div`
  background: #ffffff;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
`;


const AttachedFilesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 1rem;
  flex-wrap: wrap;
`;

const SearchIcon = styled(FaSearch)`
  color: rgb(79, 52, 210);
  font-size: 2.2rem;
  margin: 1.4rem;
`;


const inputContainer = {
  display: "flex",
  flexShrink: 0,
  alignItems: "stretch",
  height: '50px',
  marginBottom: '10px',
};

const inputStyle = {
  border: '2px solid rgb(79, 52, 210)',
  borderRadius: '5px',
};

const flexRowContainer = {
  display: 'flex',
  flexDirection: 'row',
  marginBottom: '10px',
  gap: '10px',
  marginTop: '5%',
  marginBottom:'5%'
};

const selectContainer = {
  flex: 1,
};

const buttonContainer = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  border: '2px solid rgb(79, 52, 210)',
  borderRadius: '12px',
  padding: '12px 0',
  fontSize: '14px',
  fontWeight: 'bold',
  backgroundColor: '2px solid rgb(79, 52, 210)',
  color: '2px solid rgb(79, 52, 210)',
};

const StyledUploadingComponent = styled(UploadingComponent)`
  margin: 0;
`;


function UploadFile({
  onClose,
}) {

  const [pharmacyPrescriptionId, setPharmacyPrescriptionId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showUploadOption, setShowUploadOption] = useState(true);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [phone, setPhone] = useState('');
  const [sponsor, setSponsor] = useState({});
  const [uploadProgress, setUploadProgress] = useState(0);
  const [user, setUser] = useState({});
  const [patients, setPatients] = useState([]);
  const [patient, setPatient] = useState({});
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const searchUserDataByPhone = async () => { 
    const userData = await getUserInfoByPhone(phone);
    setUser(userData.user);
    const patientList = userData.patients.map((patient) => { 
      patient.value = patient.patientId;
      patient.label = patient.name;
      return patient;
    });  
    setPatients(patientList);
    const addressList = userData.addresses.map((address) => { 
      address.value = address.addressId;
      address.label = address;
      return address;
    });
    setAddresses(addressList);
    setSponsor(userData.sponsor);
  }

  const handlePatientChange = (e) => {
    setPatient(e);
  };

  const handleAddressChange = (e) => {
    setAddress(e);
  };

  const handleAttachment =(type)=> {
    let inputElem = document.getElementById('fileAttachment');
    if (type === 'photo') {
      inputElem = document.getElementById('photoAttachment');
    }

    const file = inputElem.files[0];

    if (!file) {
      return;
    }

    const data = new FormData();

    data.append('file', file);
    if (pharmacyPrescriptionId) {
      data.append('pharmacyPrescriptionId', pharmacyPrescriptionId);
    }
    setUploading(true);
    setShowModal(false);
    const onUploadProgress = (progressEvent) => {
      if (progressEvent.lengthComputable) {
        const { loaded, total } = progressEvent;
        setUploadProgress((loaded / total) * 100);
      } else {
        setUploadProgress(100);
      }
    };

    uploadPrescriptionForMed(user.userId, data, onUploadProgress)
      .then((res) => {
        console.log(res);
        setUploading(false);
        setPharmacyPrescriptionId(res.pharmacyPrescriptionId);
        fetchPrescription();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleSubmit = async () => { 
    setLoading(true);
    console.log(user, patient, address);
    await submitDigitsationRequestMeds(
      pharmacyPrescriptionId,
      address.addressId,
      patient.patientId,
      user.userId
    )
      .then((res) => {
        if (res.message == 'success') {
          onClose();
        } else { 
          alert("Error in uploading");
          console.log(res);
        }
      })
      .catch((err) => {
        console.log(err);
      })
    setLoading(false);
  }

  const fetchPrescription =()=> {
    uploadPrescriptionForMedDashboard(user.userId)
      .then((res) => {
        const {
          previewFiles,
          pharmacyPrescriptionId,
          samplePrescription,
          FAQ,
        } = res;
        setUploadedFiles(previewFiles);
        setPharmacyPrescriptionId(pharmacyPrescriptionId);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  const removeFile =(fileId, fileIndex)=> {
    removeFileFromPrescriptionMeds(fileId, pharmacyPrescriptionId)
      .then((res) => {
        fetchPrescription();
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {

  });

  const hideModal = () => {
    
  };

  const renderUploadedFile = (ele, idx) => {
    if (ele.fileType === 'pdf') {
      return (
        <div
          style={{
            position: 'relative',
            marginRight: '24px',
            width: '52px',
            height: '76px',
            marginTop: '8px',
            marginBottom: '8px',
            borderRadius: '12px',
            backgroundColor: 'transparent',
            backgroundImage: `url(${pdfIcon})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            cursor: 'pointer',
          }}
          onClick={() => {
            document.querySelector(`#image-anchor-${ele.fileId}`).click();
          }}
        >
          <CloseContainer
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
              cursor: 'pointer',
            }}
            onTap={() => removeFile(ele.fileId, idx)}
          >
            <CloseIcon />
          </CloseContainer>
          <a
            href={ele.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            id={`image-anchor-${ele.fileId}`}
            style={{
              display: 'none',
            }}
          >
            { }
          </a>
        </div>
      );
    }

    return (
      <div
        style={{
          position: 'relative',
          marginRight: '24px',
          width: '52px',
          height: '76px',
          marginTop: '8px',
          marginBottom: '8px',
          borderRadius: '12px',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundColor: 'rgba(0,0,0,0.2)',
          backgroundImage: `url(${ele.fileUrl})`,
        }}
      >
        <CloseContainer
          style={{
            position: 'absolute',
            top: '0',
            right: '0',
            cursor: 'pointer',
          }}
          onTap={() => removeFile(ele.fileId, idx)}
        >
          <CloseIcon />
        </CloseContainer>
        <a
          href={ele.fileUrl}
          target="_blank"
          rel="noopener noreferrer"
          id={`image-anchor-${ele.fileId}`}
          style={{
            display: 'none',
          }}
        >
          { }
        </a>
        <img
          onClick={() => {
            document.querySelector(`#image-anchor-${ele.fileId}`).click();
          }}
          src={eyeIcon}
          alt="eye"
          style={{
            position: 'absolute',
            top: '24px',
            left: '12px',
            cursor: 'pointer',
          }}
        />
      </div>
    );
  };

  const formatAddressOptionLabel = (address) => (
    <p style={{paddingTop:'10px'}}>
      <b>Address</b>: {address.address}, <b>City</b>: {address.city}, <b>Locality</b>: {address.locality}, <b>Pincode</b>: {address.pincode},  <b>Latest DigitisationId</b>: {address.latestDigitisationRequestId}
      </p>
  );

  return (
    <div style={{ paddingTop: '5%' }}>
      <div style={inputContainer}>
        <input type="tel" name="telphone" placeholder="Patient Phone Number" maxLength="10" style={inputStyle} onChange={handlePhoneChange}/>
        <StyledTappable onTap={() => searchUserDataByPhone()}>
          <SearchIcon />
        </StyledTappable>
        {user.name && <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', padding: '20px' }}>
          <p style={{ margin: '10px', fontSize: '18px', color: '#333', border:'2px solid rgb(79, 52, 210)', borderRadius:'10px', padding:'5px'}}><strong>Name:</strong> {user.name}</p>
          <p style={{ margin: '10px', fontSize: '18px', color: '#333', border: '2px solid rgb(79, 52, 210)', borderRadius: '10px', padding: '5px' }}><strong>Sponsor:</strong> {sponsor.name}</p>
          <p style={{ margin: '10px', fontSize: '18px', color: '#333', border: '2px solid rgb(79, 52, 210)', borderRadius: '10px', padding: '5px' }}><strong>Phone:</strong> {user.phone}</p>
        </div>}
      </div>

      <div style={flexRowContainer}>
        <div style={selectContainer}>
          <Select options={patients} placeholder="Select Patient" onChange={handlePatientChange} />
        </div> 
      </div>
      <div style={flexRowContainer}>
        <div style={selectContainer}>
          <Select options={addresses} placeholder="Select Address" onChange={handleAddressChange} formatOptionLabel={formatAddressOptionLabel} />
        </div>
      </div>
      <div style={selectContainer}>
        {uploading && (
          <StyledUploadingComponent
            uploadProgress={`${uploadProgress}%`}
            borderRadius={0}
          />
        )}
        <input
          type="file"
          value=""
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={
            () => {
              handleAttachment('photo');
            }
          }
        />
        <input
          type="file"
          value=""
          id="fileAttachment"
          accept=".pdf"
          style={{ display: 'none' }}
          onChange={
            () => {
              handleAttachment('file');
            }
          }
        />
        <input
          type="file"
          id="captureAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={
            () => {
              handleAttachment('photo');
            }
          }
          capture="environment"
        />

        <AttachContainer className="pt-20px pb-24px pr-20px pl-20px">
          <span className="text-body-l font-regular text-txtlight" style={{fontSize:'1.2rem'}}>
            Choose only similar file format to upload prescriptions
          </span>
          <AttachedFilesContainer>
            {uploadedFiles.map(renderUploadedFile)}
            {showModal && (
              <UploadModal
                showModal={showModal}
                handleAttachment={handleAttachment}
              />
            )}  
            {uploadedFiles.length > 0 ? (
              <h1
                style={{
                  borderRadius: '12px',
                  borderColor: '#E3E3E3',
                  color: '#585969',
                  marginTop: '8px',
                  fontSize:'50px'
                }}
                onClick={() => {
                  setShowModal(true);
                  setShowUploadOption(true);
                }}
              >
                +
              </h1>
            ) : (
              <div
                className="flex flex-row items-center justify-evenly w-full pt-24px"
              // onClick={() => {
              //   setState({ showModal: true, showUploadOption: true });
              // }}
              >
                <span
                  onClick={() =>
                    document.querySelector('#photoAttachment').click()
                  }
                  style={{ cursor: 'pointer' }}
                  className="flex flex-col items-center"
                >
                  <img src={photoIcon} alt="photoIcon" />
                  <p className="text-body-s text-txtlight font-regular">
                    Image
                  </p>
                </span>
                <span
                  onClick={() =>
                    document.querySelector('#fileAttachment').click()
                  }
                  style={{ cursor: 'pointer' }}
                  className="flex flex-col items-center"
                >
                  <img src={fileIcon} alt="pdf" />
                  <p className="text-body-s text-txtlight font-regular">
                    PDF
                  </p>
                </span>
              </div>
            )}
            
          </AttachedFilesContainer>

        </AttachContainer>
      </div>
      {uploadedFiles.length > 0 && (
        <button
          onClick={handleSubmit}
          style={buttonContainer}
        >
          {!loading ? (
            <>
              <span>
                Submit
              </span>
            </>
          ) : (
              <LoadingComponent />
          )}
        </button>
      )}
    </div>
  );
}

export default (UploadFile);