import React, { Component } from 'react';
import { connect } from 'react-redux';
import { clickToCallForOrder } from '../../services/api/get-labs-orders';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import ModalConfirm from '../common/ModalConfirm';
import { MdCall } from 'react-icons/md';
import { Tooltip } from 'reactstrap';
import Truncate from '../lab-orders/Truncate';
import { getOpsCallReasons } from './UploadRxComponents/Services';
class PharmacyClickToCallButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      callReasons: [], 
      processing: false,
      expanded: false,
      confirmModal: {
        isOpen: false,
        message: 'Initiate a call between you and the user?',
        isOpsCallForPharmacy: false,
        opsCallReasons: [],
        onConfirm: () => {},
        onClose: () => {},
      },
    };
  }

  fetchCallReasons = async () => {
    try {
      const callReasons = await getOpsCallReasons();
      this.setState({ callReasons });
    } catch (error) {
      console.error('Error fetching call reasons:', error);
    }
  };
  
  componentDidMount() {
    this.fetchCallReasons();
  }

  initiateCall = (reasonForCalling) => {
    if (this.state.processing) {
      return;
    }
    this.setState({ processing: true });
    console.log('trigger call');

    clickToCallForOrder(this.props.orderId, this.props.phone, 'pharmacy-order', reasonForCalling)
      .then((response) => {
        this.setState({ processing: false });
        if (response.body.message === 'success') {
          toast.success('Call initiated');
        } else {
          toast.error(`Unable to initiate call`);
        }
      })
      .catch((err) => {
        this.setState({ processing: false });
        console.error('Unable to initiate call', err);
        toast.error(
          `Unable to initiate call -  ${err.response.data.errorMessage}`
        );
        return Promise.resolve();
      })    
  };
 
  openConfirmModal = () => {
    this.setState({
      confirmModal: {
        isOpen: true,
        message: 'Initiate a call between you and the user?',
        isOpsCallForPharmacy: true,
        opsCallReasons: this.state.callReasons,
        onConfirm:(selectedCallReason)=>{
          this.initiateCall(selectedCallReason)
          return Promise.resolve();
        },
        onClose: () => {
          this.setState({
            confirmModal: {
              isOpen: false,
              onConfirm: () => {},
              onClose: () => {},
            },
          });
        },
      },
    });
  };
  render() {
    const width = this.state.expanded ? 120 : 60;
    return (
      <div>
        <Truncate
          lines={1}
          width={width}
          ellipsis={
            <span
              onClick={() => this.setState({ expanded: !this.state.expanded })}
            >
              ...
            </span>
          }
        >
          {this.props.phone}
        </Truncate>
        
        <button
          className="btn btn-sm btn-outline-danger ml-1"
          onClick={this.openConfirmModal}
        >
          <MdCall />
        </button>
        {/*<MdCall className="ml-1" style={{"cursor": "pointer"}} onClick={this.initiateCall}/>*/}
        
        <ModalConfirm
          message={this.state.confirmModal.message}
          onConfirm={this.state.confirmModal.onConfirm}
          onClose={this.state.confirmModal.onClose}
          isOpen={this.state.confirmModal.isOpen}
          isOpsCallForPharmacy={this.state.confirmModal.isOpsCallForPharmacy}
          opsCallReasons={this.state.confirmModal.opsCallReasons} 
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PharmacyClickToCallButton);
