import React, { useState , useEffect} from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { getOpsCallReasons, sendCommunicationsToUser } from '../UploadRxComponents/Services.js';




function SendComms({ orderId, onClose }) {
  const [formData, setFormData] = useState({
    orderId: orderId,
    selectedCallReasonId : ''
  });
  const [callReasons, setCallReasons] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [errMessage, setErrorMessage] = useState('');
  useEffect(() => {
    const fetchCallReasons = async () => {
      try {
        const callReasons = await getOpsCallReasons();
        if(callReasons) {
            setCallReasons(callReasons);
        }
      } catch (error) {
        console.error("Error fetching refund reasons:", error);
      }
    };

    fetchCallReasons();
  }, []);
 



  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value, "fhjbz")
    const upperCaseValue = value.toUpperCase();
    setFormData({
      ...formData,
      [name]: upperCaseValue
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await sendCommunicationsToUser(orderId, formData.selectedCallReasonId);
    if(result.success){
        setSuccessMessage(result.message);
    }else{
        setErrorMessage(result.message);
    }
  };



  return (
    <Modal isOpen={true} style={{ maxWidth: '600px', width: '100%', borderRadius: '4px', backgroundColor: '#f8f9fa' }}>
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={()=> onClose('close-popup')}>
        <h4 className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>Send Communications</h4>
      </ModalHeader>
      <ModalBody style={{ paddingTop: '20px', paddingBottom: '20px', paddingLeft: '40px', paddingRight: '40px' }}>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="orderId">Order ID:</label>
            <input type="number"  name="orderId" className="form-control" value={orderId}  readOnly  />
          </div>


          <div className="form-group">
            <label htmlFor="callResons">Select Call Reason:</label>
                {callReasons && 
                    <select
                        id="callResons"
                        name="selectedCallReasonId"
                        className="form-control"
                        value={formData.selectedCallReasonId}
                        onChange={handleChange}
                        required
                        >
                        <option value="" disabled>Call Reasons</option>
                        {callReasons.map((reason) => (
                            <option  value={reason.id}>
                            {reason.reason}
                            </option>
                        ))}
                    </select>
                }
          </div>
            {errMessage && !successMessage && (
                <div className="text-center" style={{ color: '#ff4d4f',  backgroundColor: '#fff2f0',  border: '1px solid #ffccc7', marginBottom : '10px'}}>
                    {errMessage}
                </div>
            )}
            {successMessage && !errMessage && (
                <div className="text-center" style={{ color: '#389e0d',  backgroundColor: '#f6ffed',  border: '1px solid #b7eb8f',  marginBottom : '10px'}}>
                    {successMessage}
                </div>
            )}
          <div className="text-center">
            <button disabled = {formData.selectedCallReasonId && !successMessage && !errMessage? false : true} type="submit" className="btn btn-primary">Send Communications</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(SendComms);
