import React from "react";

const Status = ({ value, onChange, serviceTypeFilter }) => {

    const services = [
        {
            service: "Digitisation",
            statuses: [
                { label: "Pending", value: "pending" },
                { label: "Invalid", value: "invalid" },
                { label: "Complete", value: "complete" }
            ]
        },
        {
            service: "Offline",
            statuses: [
                { label: "Pending", value: "pending" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Confirmed", value: "confirmed" },
                { label: "Rx Pending", value: "prescriptionPending" },
                { label: "Rx Rejected", value: "prescriptionRejected" },
                { label: "Rx Approved", value: "prescriptionApproved" },
                // { label: "Rx Uploaded", value: "prescriptionUploaded" },
                { label: "Appt. Rescheduled", value: "apptRescheduled" },
                { label: "PAC", value: "pac" },
                { label: "UnAttempted", value: "unattempted" },
                { label: "No Show", value: "noShow" },
                { label: "Reconfirmation Awaited", value: "reconfirmationAwaited" },
                { label: "Cl Generated", value: "clGenerated" },
                { label: "Appointment Created", value: "requestReceived" },
                { label: "Appointment Delay", value: "appointmentDelay" },
                { label: "User Confirmed", value: "confirmedByUser" },
                { label: "Confirmed for Selfbook", value: "confirmedForSelfbook" },
                { label: "Check In Awaited", value: "checkInAwaited" },
                { label: "Check In", value: "checkIn" },
                { label: "On Hold", value: "onHold" }
            ]
        },
        {
            service: "Online",
            statuses: [
                { label: "Pending", value: "pending" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Complete", value: "complete" },
                { label: "Closed", value: "closed" }
            ]
        },
        {
            service: "Pharmacy",
            statuses: [
                { label: "Delivered", value: "delivered" },
                { label: "Placed", value: "placed" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Billed", value: "billed" },
                { label: "Pending", value: "pending" },
                { label: "Failed", value: "failed" },
                { label: "Delayed", value: "delayed" }
            ]
        },
        {
            service: "Vaccination",
            statuses: [
                { label: "Received", value: "received" },
                { label: "Confirmed", value: "confirmed" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Payout Completed", value: "payoutCompleted" }
            ]
        },
        {
            service: "Dental",
            statuses: [
                { label: "Received", value: "received" },
                { label: "Confirmed", value: "confirmed" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Rx Uploaded", value: "rxUploaded" },
                { label: "Pre Auth Requested", value: "preAuthRequested" },
                { label: "Pre Auth Approved", value: "preAuthApproved" },
                { label: "Rx Rejected", value: "rxRejected" },
                { label: "Rx Pending", value: "rxPending" },
                { label: "Rx Approved", value: "rxApproved" }
            ]
        },
        {
            service: "Reimbursement",
            statuses: [
                { label: "Document Uploaded", value: "documentUploaded" },
                { label: "Document Deficient", value: "documentDeficient" },
                { label: "Deficient Submitted", value: "deficientSubmitted" },
                { label: "Document Verified", value: "documentVerified" },
                { label: "Rejected", value: "rejected" },
                { label: "Approved", value: "approved" },
                { label: "Settled", value: "settled" }
            ]
        },
        {
            service: "Labs",
            statuses: [
                { label: "Order Sent to Partner", value: "orderSentToPartner" },
                { label: "Confirmed", value: "confirmed" },
                { label: "Cancelled", value: "cancelled" },
                { label: "Appointment Unconfirmed", value: "appointmentUnconfirmed" },
                { label: "Status Awaited", value: "statusAwaited" },
                { label: "Partially Attended", value: "partiallyAttended" },
                { label: "QC Approved", value: "qcApproved" },
                { label: "Attended", value: "attended" },
                { label: "QC Rejected", value: "qcRejected" },
                { label: "Report Uploaded", value: "reportUploaded" }
            ]
        }
    ];

    // Find the selected service based on serviceTypeFilter
    const selectedService = services.find(
        (service) => service.service.toLowerCase() === serviceTypeFilter.toLowerCase()
    );
    return (
        <select
            style={{
                width: "100%",
                fontSize: "12px",
                borderRadius: "10px",
                height: "32px"
            }}
            onChange={(e) => onChange(e.target.value)}
            value={value}
        >
            <option value="All">All</option>
            {selectedService
                && selectedService.statuses.map((status) => (
                    <option key={status.value} value={status.value}>
                        {status.label}
                    </option>
                ))
                }
        </select>
    );
};

export default Status;
