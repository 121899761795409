import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormGroup,
  Row,
  Col,
  Label,
} from 'reactstrap';
import { TimePicker } from 'antd';
import { toast } from 'react-toastify';
import moment from 'moment';
import LoadingComponent from '../../../components/common/LoadingComponent';
import { markFollowUpForPharmacy } from '../UploadRxComponents/Services';

const FOLLOWUP_DROPDOWN_OPTIONS = ['User', 'Ops', 'Partner'];
const FollowUpModal = ({ orderId, onClose }) => {
  const [appDate, setAppDate] = useState('');
  const [followUpTime, setFollowUpTime] = useState('');
  const [followUpReason , setFollowUpReason] = useState('');
  const [followUpRequiredFrom, setFollowUpRequiredFrom] = useState('User');
  const [loading, setLoading] = useState(false);

  const handleDateChange = (event) => {
    const selectedDate = event.target.value;

    if (!selectedDate) {
      return;
    }

    const currentDate = moment().format('YYYY-MM-DD');

    if (selectedDate >= currentDate) {
      setAppDate(selectedDate);
    } else {
      toast.warning('Please select a date equal to or after the current date.');
    }
  };

  const disabledTime = (current, type) => {
    const currentMoment = moment();
    
    if (type === 'hour' && currentMoment) {
      // Disable hours before the current hour
      return {
        disabledHours: () => [...Array(currentMoment.hours()).keys()],
      };
    }

    if (type === 'minute' && currentMoment) {
      // Disable minutes before the current minute
      return {
        disabledMinutes: () => [...Array(currentMoment.minutes()).keys()],
      };
    }

    return {};
  };

  const handleFollowUp = async () => {
    setLoading(true);
    const result = await markFollowUpForPharmacy(orderId, followUpReason, followUpRequiredFrom, appDate, followUpTime);
    setLoading(false);
    if(result){
        toast.success('Follow Up Request Created Successfully');
        onClose('close-popup')
    }else{
        toast.error('Failed To Create Follow Up Request');
    }
    
  };

  return (
    <Modal isOpen={true} style={{ maxWidth: '600px', width: '100%', borderRadius: '4px', backgroundColor: '#f8f9fa' }}>
      <ModalHeader style={{ backgroundColor: '#8752CC' }} toggle={()=> onClose('close-popup')}>
        <h4 className="text-center" style={{ color: '#fff', fontWeight: 'bold' }}>Mark Follow Up</h4>
      </ModalHeader>
      <ModalBody>
        <form>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>FollowUp Date:</Label>
              </Col>
              <Col>
                <input
                  className="form-control"
                  type="date"
                  value={appDate}
                  onChange={handleDateChange}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>FollowUp Time:</Label>
              </Col>
              <Col>
                <TimePicker
                   className="form-control"
                  disabledTime={(current) => disabledTime(current, 'hour')}
                  onChange={(_, date) => {
                    setFollowUpTime(date);
                  }}
                  format="hh:mm:00 A"
                  defaultValue={followUpTime ? moment(followUpTime, 'hh:mm:00 A'): undefined}
                />
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>Follow Up Reason:</Label>
              </Col>
              <Col >
                  <input className="form-control" placeholder='Input Reason For Follow Up' onChange={(e) => setFollowUpReason(e.target.value)}/>
              </Col>
            </Row>
          </FormGroup>
          <FormGroup>
            <Row>
              <Col className="col-3">
                <Label>Follow Up required from:</Label>
              </Col>
              <Col>
                <select
                  className="form-control"
                  value={followUpRequiredFrom}
                  onChange={(e) => {
                    setFollowUpRequiredFrom(e.target.value);
                  }}
                >
                  {FOLLOWUP_DROPDOWN_OPTIONS.map((dropdownOption) => (
                    <option>{dropdownOption}</option>
                  ))}
                </select>{' '}
              </Col>
            </Row>
          </FormGroup>
        </form>
      </ModalBody>
      <ModalFooter>
        {loading ? <LoadingComponent color={'black'} /> : <></>}
        <div className="text-center" style={{margin : 'auto'}}>
            <Button color="primary" disabled={loading || !followUpTime || !appDate || !followUpReason} onClick={() => handleFollowUp()}>
                Confirm
            </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default FollowUpModal;
